import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IBundleOption, IBundleOptionValue } from 'graphql/customer/customerOrdersInterfaces';
import { SummaryLine } from 'ui/component/sales-components/summary-line';
import salesPrintStyles from '../style.css';

export interface IBundleOptionsParams {
    options?: IBundleOption[];
    className?: string;
}

export const BundleOptions: React.FC<IBundleOptionsParams> = (
    {
        options,
        className,
    },
) => {
    const t = usePhraseTranslater();
    if (!options || !options.length) {
        return null;
    }

    return (
        <div className={classes(salesPrintStyles.bundleOptionsWrapper, (className || ''))}>
            {options.map(
                (bundleOption: IBundleOption) => {
                    const {
                        values,
                    } = bundleOption;
                    return (
                        <>
                            {values.length > 0 && values.map(
                                (optionValue: IBundleOptionValue) => {
                                    const {
                                        product_name: productName,
                                        product_sku: productSku,
                                        price,
                                        quantity,
                                    } = optionValue;
                                    const { value } = price;
                                    const bundleOptionRowPrice = (value * quantity);
                                    const itemInfo = t('%1 of: %2', quantity, `${productName} (${productSku})`);
                                    return (
                                        <div className={classes(salesPrintStyles.printItemRow)}>
                                            <div className={salesPrintStyles.printItemInfoRow}>{itemInfo}</div>
                                            <div>-</div>
                                            <SummaryLine
                                                amount={bundleOptionRowPrice}
                                                lineClassName={salesPrintStyles.printItemInfoRow}
                                                priceClassName={salesPrintStyles.itemPrice}
                                            />
                                        </div>
                                    );
                                },
                            )}
                        </>
                    );
                },
            )}
        </div>
    );
};
