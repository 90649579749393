import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { useLazyQuery } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { GET_DYNAMIC_WIDGETS, IDynamicWidget } from 'graphql/cms/dynamic-widgets';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { Logo } from 'ui/component/header/persistent-header/top/logo';
import { Link } from 'ui/component/custom/link';
import styles from './style.css';

export interface IPrintBlankParams {
    title: string;
    order: ICustomerOrder;
    children?: JSX.Element;
}

export const PrintBlank: React.FC<IPrintBlankParams> = (
    {
        title,
        order,
        children,
    },
) => {
    const [printFooterWidgetBlocks, setPrintFooterWidgetBlocks] = useState<IDynamicWidget[]|null>(null);

    const t = usePhraseTranslater();

    const [getPrintFooterWidget] = useLazyQuery(GET_DYNAMIC_WIDGETS, {
        variables: {
            location: 'PRINTFOOTER',
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: () => {
            setPrintFooterWidgetBlocks(null);
        },
        onCompleted: (data): void => {
            const { getDynamicWidgets } = data;
            setPrintFooterWidgetBlocks(getDynamicWidgets);
        },
    });

    const loadWidgetData = async () => {
        await getPrintFooterWidget();
    };

    const processHtml = (html: string): { __html: string } => ({ __html: DOMPurify.sanitize(html) });

    const getBlockContent = (widgetBlock: IDynamicWidget): JSX.Element => {
        const content = processHtml(widgetBlock.content);
        // eslint-disable-next-line react/no-danger
        return (<div dangerouslySetInnerHTML={content} className={styles.widgetBlock} />);
    };

    useEffect(() => {
        loadWidgetData();
    }, []);

    if (!order) {
        return null;
    }

    const { order_urls: orderUrls } = order;
    const { details_url: orderViewUrl } = orderUrls;

    return (
        <div className={classes(styles.printBlank)}>
            <div className={classes(styles.printBlankRow, styles.printBlankHeader)}>
                <div className={styles.printBlankLogoWrapper}>
                    <Logo className={styles.printBlankLogo} />
                </div>
            </div>
            <div className={classes(styles.printBlankRow, styles.printBlankInfo)}>
                <div className={styles.printBlankTitle}>
                    <div className={styles.title}>{title}</div>
                </div>
                <div className={styles.printBlankContent}>
                    {children && (children)}
                </div>
            </div>
            <div className={classes(styles.printBlankRow, styles.printBlankFooter)}>
                <div className={classes(styles.printFooterRow, styles.orderViewUrl)}>
                    <div className={styles.text}>
                        {t('To view the status of your order, return to')}
                    </div>
                    <div className={styles.link}>
                        <Link
                            label={t('Order Summary.')}
                            href={orderViewUrl}
                            linkClassName={styles.printBlankUrl}
                            opacityOnHover
                        />
                    </div>
                </div>
                <div className={classes(styles.printFooterRow, styles.policies)}>
                    {printFooterWidgetBlocks && printFooterWidgetBlocks.map(
                        (widgetBlock: IDynamicWidget) => (
                            <>{getBlockContent(widgetBlock)}</>
                        ),
                    )}
                </div>
            </div>
        </div>
    );
};
