import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    ICustomerOrder,
    IOrderShipment,
    IOrderItem,
} from 'graphql/customer/customerOrdersInterfaces';
import { PrintBlank } from 'ui/component/sales-components/sales-details-layout/sales-print';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { PrintSection } from 'ui/component/sales-components/sales-details-layout/sales-print/print-section';
import {
    PrintHead,
    PrintItems,
    PrintShipping,
    PrintPayment,
} from 'ui/component/sales-components/sales-details-layout/sales-print/print-info';
import { getShipmentOrderItems } from '../../../../util/get-order-items-data';
import styles from '../../../style.css';

export interface IPrintShipmentParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    shipment: IOrderShipment;
}

export const PrintShipment: React.FC<IPrintShipmentParams> = (
    {
        config,
        order,
        shipment,
    },
) => {
    const t = usePhraseTranslater();
    if (!order || !shipment) {
        return null;
    }

    const {
        number: orderNumber,
        order_date_formatted: orderDate,
        total: orderTotal,
    } = order;

    const {
        number,
        dateFormatted,
        items: shipmentItems,
        tracking,
    } = shipment;

    const shipmentOrderItems: IOrderItem[] = getShipmentOrderItems(shipmentItems);

    const printTitle = t('Final Details for Shipment #%1', number);

    return (
        <PrintBlank title={printTitle} order={order}>
            <>
                <PrintSection dataTest="order-info">
                    <div className={styles.orderPrintEntityHead}>
                        <PrintHead
                            config={config}
                            entityTypeLabel={t('order')}
                            entityNumber={orderNumber}
                            entityDateLabel={t('Order Placed:')}
                            entityDate={orderDate}
                            entityTotalLabel={t('Order Total:')}
                            salesTotal={orderTotal}
                        />
                        <PrintHead
                            config={config}
                            entityTypeLabel={t('shipment')}
                            entityNumber={number}
                            entityDateLabel={t('Shipped:')}
                            entityDate={dateFormatted}
                        />
                    </div>
                </PrintSection>
                <PrintSection label={t('Shipped on %1', dateFormatted)} dataTest="shipment-items-info">
                    <>
                        <PrintItems
                            itemsActionLabel={t('Items Shipped')}
                            orderItems={shipmentOrderItems}
                        />
                        <PrintShipping
                            order={order}
                            tracking={tracking}
                        />
                    </>
                </PrintSection>
                <PrintSection label={t('Payment Information')} dataTest="shipment-payment-info">
                    <PrintPayment
                        order={order}
                        showTotal={false}
                        showTransactions={false}
                    />
                </PrintSection>
            </>
        </PrintBlank>
    );
};
