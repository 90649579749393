import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePriceFormatter, usePriceRounder } from '@silkpwa/module/ecommerce-catalog/config';
import styles from './style.css';

interface ISummaryLineProps {
    amount: number;
    label?: string;
    subLabel?: string;
    isDiscount?: boolean;
    lineClassName?: string;
    noOriginalLineClassName?: boolean;
    lineRowClassName?: string;
    labelClassName?: string;
    subLabelClassName?: string;
    priceClassName?: string;
}

export const SummaryLine: React.FC<ISummaryLineProps> = (
    {
        amount,
        label,
        subLabel,
        isDiscount = false,
        lineClassName,
        noOriginalLineClassName,
        lineRowClassName,
        labelClassName,
        subLabelClassName,
        priceClassName,
    },
) => {
    const formatPrice = usePriceFormatter();
    const roundPrice = usePriceRounder();

    const getPrice = (price: number) => formatPrice(roundPrice(price));

    const linePrice = getPrice(amount);

    return (
        <div
            className={classes({
                [styles.summaryLine]: !noOriginalLineClassName,
            }, (lineClassName || ''))}
        >
            {label && (
                <div className={classes(styles.lineRow, styles.labelRow, (lineRowClassName || ''))}>
                    <div className={classes(styles.label, (labelClassName || ''))}>{label}</div>
                    {subLabel && (
                        <div className={classes(styles.subLabel, (subLabelClassName || ''))}>{`(${subLabel})`}</div>
                    )}
                </div>
            )}
            <div className={classes(styles.lineRow, styles.priceRow, (lineRowClassName || ''))}>
                <div className={classes(styles.price, (priceClassName || ''))}>
                    {(isDiscount ? `-${linePrice}` : linePrice)}
                </div>
            </div>
        </div>
    );
};
