import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { LoadingImage } from 'ui/component/loading-image';
import styles from './style.css';

export interface IAccountPageContentProps {
    pageTitle?: string;
    className?: string;
    pageContentClassName?: string;
    pageLoading?: boolean;
    fullWidth?: boolean;
    children?: JSX.Element;
}
export const PageContent: React.FC<IAccountPageContentProps> = (
    {
        pageTitle,
        className,
        pageContentClassName,
        pageLoading = true,
        fullWidth = true,
        children,
    },
) => (
    <div
        className={classes(styles.pageContentWrapper, {
            [styles.fullWidth]: fullWidth,
        }, (className || ''))}
    >
        {pageTitle && (
            <h1 className={styles.pageTitle}>{pageTitle}</h1>
        )}
        {pageLoading && (
            <LoadingImage parentClass={styles.pageLoading} />
        )}
        {!pageLoading && children && (
            <div className={classes(styles.pageContent, (pageContentClassName || ''))}>
                {children}
            </div>
        )}
    </div>
);
