import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICustomerOrder, ISalesTotal } from 'graphql/customer/customerOrdersInterfaces';
import { OrderAddress } from '../../order-information/order-address';
import { PaymentMethods } from '../../order-information/payment-methods';
import { SalesSummary } from '../../sales-summary';
import { OrderTransactions } from './order-transactions';
import styles from './style.css';

export interface IPrintPaymentParams {
    order: ICustomerOrder;
    total?: ISalesTotal;
    showTotal?: boolean;
    showTransactions?: boolean;
}

export const PrintPayment: React.FC<IPrintPaymentParams> = (
    {
        order,
        total,
        showTotal = true,
        showTransactions = true,
    },
) => {
    const t = usePhraseTranslater();

    const {
        payment_methods: paymentMethods,
        billing_address: billingAddress,
        transactions,
        total: orderTotal,
    } = order;

    const salesTotal = total || orderTotal;

    return (
        <>
            <div className={classes(styles.subSection, styles.paymentAndTotal)}>
                <div className={classes(styles.subSectionColumn, styles.left)}>
                    <PaymentMethods
                        paymentMethods={paymentMethods}
                        label={t('Payment Method:')}
                        labelClassName={styles.label}
                    />
                    <div className={styles.billingAddress}>
                        <div className={styles.label}>{t('Billing Address:')}</div>
                        <OrderAddress
                            orderAddress={billingAddress}
                        />
                    </div>
                </div>
                {showTotal && (
                    <div className={classes(styles.subSectionColumn, styles.right)}>
                        <SalesSummary
                            salesTotal={salesTotal}
                            className={styles.summaryWrapper}
                            lineClassName={styles.summaryLine}
                            lineRowClassName={styles.summaryLineRow}
                            showSeparators
                        />
                    </div>
                )}
            </div>
            {showTransactions && (
                <OrderTransactions
                    transactions={transactions}
                />
            )}
        </>
    );
};
