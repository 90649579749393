import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { getShipments } from 'ui/page/sales-pages/util/process-order-entities';
import { ICustomerOrder, IOrderShipment } from 'graphql/customer/customerOrdersInterfaces';
import { PrintShipments } from './order-print-entity/print-shipments';
import styles from '../style.css';

export interface IShipmentsPrintParams {
    config: ISalesLayoutConfig;
    order?: ICustomerOrder;
    shipmentNumber: string;
}

export const ShipmentsPrint: React.FC<IShipmentsPrintParams> = (
    {
        config,
        order,
        shipmentNumber,
    },
) => {
    if (!order) {
        return null;
    }

    const {
        shipments,
    } = order;

    const printShipments: IOrderShipment[] = getShipments(shipments, shipmentNumber);

    return (
        <div className={classes(styles.shipmentsPrintWrapper)}>
            <PrintShipments config={config} order={order} shipments={printShipments} />
        </div>
    );
};
