import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Link } from 'ui/component/custom/link';
import styles from './style.css';

export interface IPrintLayoutParams {
    children?: React.ReactNode;
}

export const PrintLayout: React.FC<IPrintLayoutParams> = (
    {
        children,
    },
) => {
    const t = usePhraseTranslater();
    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({ contentRef });

    const onPrintClick = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        reactToPrintFn();
    };

    return (
        <div className={classes(styles.printLayoutWrapper)}>
            <div className={styles.printLayoutHeader}>
                <Link
                    label={t('Print For Your Records')}
                    href="/"
                    forceUnderline
                    opacityOnHover
                    action={onPrintClick}
                />
            </div>
            <div className={styles.printLayoutContentWrapper}>
                <div ref={contentRef} className={styles.printLayoutContent}>
                    {children && (children)}
                </div>
            </div>
        </div>
    );
};
