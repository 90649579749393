import React from 'react';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ICustomerOrder, IOrderShipment } from 'graphql/customer/customerOrdersInterfaces';
import { PrintLayout } from 'ui/component/sales-components/sales-details-layout/sales-print';
import { PrintShipment } from './print-shipment';

export interface IPrintShipmentsParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    shipments: IOrderShipment[];
}

export const PrintShipments: React.FC<IPrintShipmentsParams> = (
    {
        config,
        order,
        shipments,
    },
) => {
    if (!shipments || !shipments.length) {
        return null;
    }

    return (
        <PrintLayout>
            {shipments.map(
                (shipment: IOrderShipment) => (
                    <PrintShipment config={config} order={order} shipment={shipment} />
                ),
            )}
        </PrintLayout>
    );
};
