import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export interface IPrintSectionParams {
    label?: string;
    className?: string;
    dataTest?: string;
    children?: JSX.Element;
}

export const PrintSection: React.FC<IPrintSectionParams> = (
    {
        label,
        className,
        dataTest,
        children,
    },
) => (
    <div className={classes(styles.printSection, (className || ''))} data-test={`print-section-${dataTest}`}>
        {label && (
            <div className={styles.printSectionLabel}>{label}</div>
        )}
        {children && (children)}
    </div>
);
