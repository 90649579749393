import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICustomerOrder, ITracking } from 'graphql/customer/customerOrdersInterfaces';
import { OrderAddress } from '../../order-information/order-address';
import { ShipmentTracking } from './shipment-tracking';
import styles from './style.css';

export interface IPrintShippingParams {
    order?: ICustomerOrder;
    tracking?: ITracking[];
}

export const PrintShipping: React.FC<IPrintShippingParams> = (
    {
        order,
        tracking,
    },
) => {
    const t = usePhraseTranslater();

    if (!order) {
        return null;
    }

    const {
        shipping_address: shippingAddress,
        shipping_method: shippingMethod,
    } = order;

    return (
        <>
            <div className={classes(styles.subSection, styles.printShipmentWrapper)}>
                <div className={classes(styles.subSectionColumn, styles.shippingAddress)}>
                    <div className={styles.label}>{t('Shipping Address:')}</div>
                    <OrderAddress
                        orderAddress={shippingAddress}
                    />
                </div>
                <div className={classes(styles.subSectionColumn, styles.shippingMethod)}>
                    <div className={styles.label}>{t('Shipping Method:')}</div>
                    <div>{shippingMethod}</div>
                </div>
                <ShipmentTracking
                    tracking={tracking}
                />
            </div>
        </>
    );
};
