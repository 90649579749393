import React from 'react';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ICustomerOrder, IOrderCreditMemo } from 'graphql/customer/customerOrdersInterfaces';
import { PrintLayout } from 'ui/component/sales-components/sales-details-layout/sales-print';
import { PrintRefund } from './print-refund';

export interface IPrintRefundsParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    refunds: IOrderCreditMemo[];
}

export const PrintRefunds: React.FC<IPrintRefundsParams> = (
    {
        config,
        order,
        refunds,
    },
) => {
    if (!refunds || !refunds.length) {
        return null;
    }

    return (
        <PrintLayout>
            {refunds.map(
                (refund: IOrderCreditMemo) => (
                    <PrintRefund config={config} order={order} refund={refund} />
                ),
            )}
        </PrintLayout>
    );
};
