import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { getRefunds } from 'ui/page/sales-pages/util/process-order-entities';
import { ICustomerOrder, IOrderCreditMemo } from 'graphql/customer/customerOrdersInterfaces';
import { PrintRefunds } from './order-print-entity/print-refunds';
import styles from '../style.css';

export interface IRefundsPrintParams {
    config: ISalesLayoutConfig;
    order?: ICustomerOrder;
    refundId: string;
}

export const RefundsPrint: React.FC<IRefundsPrintParams> = (
    {
        config,
        order,
        refundId,
    },
) => {
    if (!order) {
        return null;
    }

    const {
        credit_memos: refunds,
    } = order;

    const printRefunds: IOrderCreditMemo[] = getRefunds(refunds, refundId);

    return (
        <div className={classes(styles.refundsPrintWrapper)}>
            <PrintRefunds config={config} order={order} refunds={printRefunds} />
        </div>
    );
};
