import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IOrderTransaction } from 'graphql/customer/customerOrdersInterfaces';
import { SummaryLine } from 'ui/component/sales-components/summary-line';
import salesPrintStyles from '../style.css';

export interface IOrderTransactionsParams {
    transactions: IOrderTransaction[];
}

export const OrderTransactions: React.FC<IOrderTransactionsParams> = (
    {
        transactions,
    },
) => {
    const t = usePhraseTranslater();
    if (!transactions || !transactions.length) {
        return null;
    }

    return (
        <div className={classes(salesPrintStyles.subSection, salesPrintStyles.transactions)}>
            {transactions.map(
                (transaction) => {
                    const {
                        label,
                        value,
                    } = transaction;
                    return (
                        <>
                            <div className={classes(salesPrintStyles.subSectionColumn, salesPrintStyles.left)}>
                                <div className={salesPrintStyles.label}>{t('Credit Card transaction:')}</div>
                            </div>
                            <div className={classes(salesPrintStyles.subSectionColumn, salesPrintStyles.right)}>
                                <div className={classes(salesPrintStyles.text, salesPrintStyles.left)}>{label}</div>
                                <SummaryLine
                                    amount={value}
                                    lineClassName={classes(salesPrintStyles.text, salesPrintStyles.right)}
                                    lineRowClassName={salesPrintStyles.paymentPrice}
                                />
                            </div>
                        </>
                    );
                },
            )}
        </div>
    );
};
