import React from 'react';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { PrintBlank, PrintLayout } from 'ui/component/sales-components/sales-details-layout/sales-print';

interface IPrintSalesEntityProps {
    printTitle: string;
    order: ICustomerOrder;
    children?: React.ReactNode;
}

export const PrintSalesEntity: React.FC<IPrintSalesEntityProps> = (
    {
        printTitle,
        order,
        children,
    },
) => (
    <PrintLayout>
        <PrintBlank title={printTitle} order={order}>
            <>{children && (children)}</>
        </PrintBlank>
    </PrintLayout>
);
