import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { ISalesLayoutConfig, currentOrderVar } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { PrintSalesEntity } from 'ui/component/sales-components/sales-details-layout/sales-print/print-sales-entity';
import { PrintSection } from 'ui/component/sales-components/sales-details-layout/sales-print/print-section';
import {
    PrintHead,
    PrintItems,
    PrintPayment,
    PrintShipping,
} from 'ui/component/sales-components/sales-details-layout/sales-print/print-info';

export interface IOrderPrintParams {
    config: ISalesLayoutConfig;
}

export const OrderPrint: React.FC<IOrderPrintParams> = (
    {
        config,
    },
) => {
    const t = usePhraseTranslater();
    const currentOrder: ICustomerOrder|undefined = useReactiveVar(currentOrderVar);

    if (!currentOrder) {
        return null;
    }

    const {
        number,
        order_date_formatted: orderDate,
        total,
    } = currentOrder;

    /**
     * We do not need to show order refunded to store credit amount on the print order page
     */
    const orderTotal = {
        ...total,
        total_refunded_to_store_credit: undefined,
    };

    const printTitle = t('Final Details for Order #%1', number);

    return (
        <PrintSalesEntity printTitle={printTitle} order={currentOrder}>
            <PrintSection dataTest="order-info">
                <PrintHead
                    config={config}
                    entityTypeLabel={t('order')}
                    entityNumber={number}
                    entityDateLabel={t('Order Placed:')}
                    entityDate={orderDate}
                    entityTotalLabel={t('Order Total:')}
                    salesTotal={total}
                />
            </PrintSection>
            <PrintSection label={t('Ordered on %1', orderDate)} dataTest="shipping-info">
                <>
                    <PrintItems
                        itemsActionLabel={t('Items Ordered')}
                        order={currentOrder}
                    />
                    <PrintShipping
                        order={currentOrder}
                    />
                </>
            </PrintSection>
            <PrintSection label={t('Payment Information')} dataTest="payment-info">
                <PrintPayment
                    order={currentOrder}
                    total={orderTotal}
                />
            </PrintSection>
        </PrintSalesEntity>
    );
};
