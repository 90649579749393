import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ITracking } from 'graphql/customer/customerOrdersInterfaces';
import styles from '../style.css';

export interface IShipmentTrackingParams {
    tracking?: ITracking[];
}

export const ShipmentTracking: React.FC<IShipmentTrackingParams> = (
    {
        tracking,
    },
) => {
    const t = usePhraseTranslater();
    if (!tracking || !tracking.length) {
        return null;
    }

    const { length } = tracking;

    return (
        <div className={classes(styles.subSectionColumn, styles.shippingTracking)}>
            <>
                <div className={styles.label}>{(length > 1 ? t('Tracking Numbers:') : t('Tracking Number:'))}</div>
                {tracking.map(
                    (track: ITracking) => {
                        const {
                            title,
                            number,
                        } = track;
                        return (
                            <div className={classes(styles.subSectionRow, styles.shippingTrack)}>
                                <div className={styles.label}>{title}</div>
                                <div>{number}</div>
                            </div>
                        );
                    },
                )}
            </>
        </div>
    );
};
