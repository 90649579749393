import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    ICustomerOrder,
    IOrderInvoice,
    IOrderItem,
} from 'graphql/customer/customerOrdersInterfaces';
import { PrintBlank } from 'ui/component/sales-components/sales-details-layout/sales-print';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { PrintSection } from 'ui/component/sales-components/sales-details-layout/sales-print/print-section';
import {
    PrintHead,
    PrintItems,
    PrintPayment,
    PrintShipping,
} from 'ui/component/sales-components/sales-details-layout/sales-print/print-info';
import { getInvoiceOrderItems } from '../../../../util/get-order-items-data';
import styles from '../../../style.css';

export interface IPrintInvoiceParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    invoice: IOrderInvoice;
}

export const PrintInvoice: React.FC<IPrintInvoiceParams> = (
    {
        config,
        order,
        invoice,
    },
) => {
    const t = usePhraseTranslater();
    if (!order || !invoice) {
        return null;
    }

    const {
        number: orderNumber,
        order_date_formatted: orderDate,
        total: orderTotal,
    } = order;

    const {
        number,
        dateFormatted,
        items: invoiceItems,
        total: invoiceTotal,
    } = invoice;

    const invoiceOrderItems: IOrderItem[] = getInvoiceOrderItems(invoiceItems);

    const printTitle = t('Final Details for Invoice #%1', number);

    return (
        <PrintBlank title={printTitle} order={order}>
            <>
                <PrintSection dataTest="order-info">
                    <div className={styles.orderPrintEntityHead}>
                        <PrintHead
                            config={config}
                            entityTypeLabel={t('order')}
                            entityNumber={orderNumber}
                            entityDateLabel={t('Order Placed:')}
                            entityDate={orderDate}
                            entityTotalLabel={t('Order Total:')}
                            salesTotal={orderTotal}
                        />
                        <PrintHead
                            config={config}
                            entityTypeLabel={t('invoice')}
                            entityNumber={number}
                            entityDateLabel={t('Invoiced:')}
                            entityDate={dateFormatted}
                            entityTotalLabel={t('Invoice Total:')}
                            salesTotal={invoiceTotal}
                        />
                    </div>
                </PrintSection>
                <PrintSection label={t('Invoiced on %1', dateFormatted)} dataTest="invoice-items-info">
                    <>
                        <PrintItems
                            itemsActionLabel={t('Items Invoiced')}
                            orderItems={invoiceOrderItems}
                        />
                        <PrintShipping
                            order={order}
                        />
                    </>
                </PrintSection>
                <PrintSection label={t('Invoice Payment Information')} dataTest="invoice-payment-info">
                    <PrintPayment
                        order={order}
                        total={invoiceTotal}
                    />
                </PrintSection>
            </>
        </PrintBlank>
    );
};
