import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ICustomerOrder, IOrderInvoice } from 'graphql/customer/customerOrdersInterfaces';
import { getInvoices } from 'ui/page/sales-pages/util/process-order-entities';
import { PrintInvoices } from './order-print-entity/print-invoices';
import styles from '../style.css';

export interface IInvoicesPrintParams {
    config: ISalesLayoutConfig;
    order?: ICustomerOrder;
    invoiceId: string;
}

export const InvoicesPrint: React.FC<IInvoicesPrintParams> = (
    {
        config,
        order,
        invoiceId,
    },
) => {
    if (!order) {
        return null;
    }

    const {
        invoices,
    } = order;

    const printInvoices: IOrderInvoice[] = getInvoices(invoices, invoiceId);

    return (
        <div className={classes(styles.invoicesPrintWrapper)}>
            <PrintInvoices config={config} order={order} invoices={printInvoices} />
        </div>
    );
};
