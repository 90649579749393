import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    ICustomerOrder,
    IOrderCreditMemo,
    IOrderItem,
} from 'graphql/customer/customerOrdersInterfaces';
import { PrintBlank } from 'ui/component/sales-components/sales-details-layout/sales-print';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { PrintSection } from 'ui/component/sales-components/sales-details-layout/sales-print/print-section';
import {
    PrintHead,
    PrintItems,
    PrintPayment,
    PrintShipping,
} from 'ui/component/sales-components/sales-details-layout/sales-print/print-info';
import { getRefundOrderItems } from '../../../../util/get-order-items-data';
import styles from '../../../style.css';

export interface IPrintRefundParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    refund: IOrderCreditMemo;
}

export const PrintRefund: React.FC<IPrintRefundParams> = (
    {
        config,
        order,
        refund,
    },
) => {
    const t = usePhraseTranslater();
    if (!order || !refund) {
        return null;
    }

    const {
        number: orderNumber,
        order_date_formatted: orderDate,
        total: orderTotal,
    } = order;

    const {
        number,
        dateFormatted,
        items: refundItems,
        total: refundTotal,
    } = refund;

    const refundOrderItems: IOrderItem[] = getRefundOrderItems(refundItems);

    const printTitle = t('Final Details for Refund #%1', number);

    return (
        <PrintBlank title={printTitle} order={order}>
            <>
                <PrintSection dataTest="order-info">
                    <div className={styles.orderPrintEntityHead}>
                        <PrintHead
                            config={config}
                            entityTypeLabel={t('order')}
                            entityNumber={orderNumber}
                            entityDateLabel={t('Order Placed:')}
                            entityDate={orderDate}
                            entityTotalLabel={t('Order Total:')}
                            salesTotal={orderTotal}
                        />
                        <PrintHead
                            config={config}
                            entityTypeLabel={t('refund')}
                            entityNumber={number}
                            entityDateLabel={t('Refunded:')}
                            entityDate={dateFormatted}
                            entityTotalLabel={t('Refund Total:')}
                            salesTotal={refundTotal}
                        />
                    </div>
                </PrintSection>
                <PrintSection label={t('Refunded on %1', dateFormatted)} dataTest="refund-items-info">
                    <>
                        <PrintItems
                            itemsActionLabel={t('Items Refunded')}
                            orderItems={refundOrderItems}
                        />
                        <PrintShipping
                            order={order}
                        />
                    </>
                </PrintSection>
                <PrintSection label={t('Refund Payment Information')} dataTest="refund-payment-info">
                    <PrintPayment
                        order={order}
                        total={refundTotal}
                        showTransactions={false}
                    />
                </PrintSection>
            </>
        </PrintBlank>
    );
};
