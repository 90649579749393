import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IOrderItem } from 'graphql/customer/customerOrdersInterfaces';
import { SummaryLine } from 'ui/component/sales-components/summary-line';
import salesPrintStyles from '../style.css';

export interface IEmbItemsParams {
    embroideryItems?: IOrderItem[];
    className?: string;
}

export const EmbItems: React.FC<IEmbItemsParams> = (
    {
        embroideryItems,
        className,
    },
) => {
    if (!embroideryItems || !embroideryItems.length) {
        return null;
    }

    return (
        <div className={classes(salesPrintStyles.embroideryItemsWrapper, (className || ''))}>
            {embroideryItems.map(
                (embroideryItem: IOrderItem) => {
                    const {
                        product_name: productName,
                        product_sale_price: salePrice,
                        quantity_ordered: qty,
                    } = embroideryItem;
                    const { value: embPrice } = salePrice;
                    const embItemRowPrice = (embPrice * qty);
                    return (
                        <div className={classes(salesPrintStyles.printItemRow)}>
                            <div className={salesPrintStyles.printItemInfoRow}>{productName}</div>
                            <SummaryLine
                                amount={embItemRowPrice}
                                lineClassName={salesPrintStyles.printItemInfoRow}
                                priceClassName={salesPrintStyles.itemPrice}
                            />
                        </div>
                    );
                },
            )}
        </div>
    );
};
