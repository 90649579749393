import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICustomerOrder, IOrderItem } from 'graphql/customer/customerOrdersInterfaces';
import { SummaryLine } from 'ui/component/sales-components/summary-line';
import { BundleOptions } from './bundle-options';
import { EmbItems } from './emb-items';
import styles from './style.css';

export interface IPrintItemsParams {
    itemsActionLabel: string;
    order?: ICustomerOrder;
    orderItems?: IOrderItem[];
    className?: string;
}

export const PrintItems: React.FC<IPrintItemsParams> = (
    {
        itemsActionLabel,
        order,
        orderItems,
        className,
    },
) => {
    const t = usePhraseTranslater();
    const finalOrderItems: IOrderItem[] = orderItems || (order?.items || []);
    if (!finalOrderItems || !finalOrderItems.length) {
        return null;
    }

    return (
        <div
            className={classes(
                styles.printItemsWrapper,
                styles.subSection,
                styles.orderItems,
                (className || ''),
            )}
        >
            <div className={classes(styles.printItemsRow, styles.headers)}>
                <div className={styles.header}>{itemsActionLabel}</div>
                <div className={styles.header}>{t('Price')}</div>
            </div>
            <div className={classes(styles.printItemsRow, styles.items)}>
                {finalOrderItems.map(
                    (orderItem: IOrderItem) => {
                        const {
                            product_name: name,
                            product_sku: sku,
                            product_sale_price: salePrice,
                            quantity_ordered: qty,
                            embroideryItems,
                            bundle_options: bundleOptions,
                        } = orderItem;
                        const { value } = salePrice;
                        const rowPrice = (value * qty);
                        const itemInfo = t('%1 of: %2', qty, `${name} (${sku})`);
                        return (
                            <>
                                <div className={styles.printItemRow}>
                                    <div className={styles.printItemInfoRow}>{itemInfo}</div>
                                    <SummaryLine
                                        amount={rowPrice}
                                        lineClassName={styles.printItemInfoRow}
                                        priceClassName={styles.itemPrice}
                                    />
                                </div>
                                {bundleOptions && (
                                    <BundleOptions options={bundleOptions} />
                                )}
                                <EmbItems embroideryItems={embroideryItems} />
                            </>
                        );
                    },
                )}
            </div>
        </div>
    );
};
