import {
    IOrderInvoice,
    IOrderShipment,
    IOrderCreditMemo,
} from 'graphql/customer/customerOrdersInterfaces';

const getUid = (id: string) => Buffer.from(id).toString('base64');
const matchInvoiceId = (invoice: IOrderInvoice, id: string) => (invoice.id === getUid(id));
const matchShipmentNumber = (shipment: IOrderShipment, number: string) => (shipment.id === getUid(number));
const matchRefundId = (refund: IOrderCreditMemo, id: string) => (refund.id === getUid(id));

const filterInvoicesById = (
    invoices: IOrderInvoice[],
    findById: string,
): IOrderInvoice[] => invoices.filter((invoice: IOrderInvoice) => matchInvoiceId(invoice, findById));

const filterShipmentsByNumber = (
    shipments: IOrderShipment[],
    findByNumber: string,
): IOrderShipment[] => shipments.filter((shipment: IOrderShipment) => matchShipmentNumber(shipment, findByNumber));

const filterRefundsById = (
    refunds: IOrderCreditMemo[],
    findById: string,
): IOrderCreditMemo[] => refunds.filter((refund: IOrderCreditMemo) => matchRefundId(refund, findById));

export const findInvoiceById = (
    invoices: IOrderInvoice[],
    findById: string,
): IOrderInvoice|undefined => invoices.find((invoice: IOrderInvoice) => matchInvoiceId(invoice, findById));

export const findShipmentByNumber = (
    shipments: IOrderShipment[],
    findByNumber: string,
): IOrderShipment|undefined => shipments.find(
    (shipment: IOrderShipment) => matchShipmentNumber(shipment, findByNumber),
);

export const findRefundById = (
    refunds: IOrderCreditMemo[],
    findById: string,
): IOrderCreditMemo|undefined => refunds.find((refund: IOrderCreditMemo) => matchRefundId(refund, findById));

export const getInvoices = (
    invoices: IOrderInvoice[],
    invoiceId: string,
): IOrderInvoice[] => (invoiceId === '' ? invoices : filterInvoicesById(invoices, invoiceId));

export const getShipments = (
    shipments: IOrderShipment[],
    shipmentNumber: string,
): IOrderShipment[] => (shipmentNumber === '' ? shipments : filterShipmentsByNumber(shipments, shipmentNumber));

export const getRefunds = (
    refunds: IOrderCreditMemo[],
    refundId: string,
): IOrderCreditMemo[] => (refundId === '' ? refunds : filterRefundsById(refunds, refundId));
