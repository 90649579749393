import React from 'react';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ICustomerOrder, IOrderInvoice } from 'graphql/customer/customerOrdersInterfaces';
import { PrintLayout } from 'ui/component/sales-components/sales-details-layout/sales-print';
import { PrintInvoice } from './print-invoice';

export interface IPrintInvoicesParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    invoices: IOrderInvoice[];
}

export const PrintInvoices: React.FC<IPrintInvoicesParams> = (
    {
        config,
        order,
        invoices,
    },
) => {
    if (!invoices || !invoices.length) {
        return null;
    }

    return (
        <PrintLayout>
            {invoices.map(
                (invoice: IOrderInvoice) => (
                    <PrintInvoice config={config} order={order} invoice={invoice} />
                ),
            )}
        </PrintLayout>
    );
};
