import React, { useState, useEffect } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ISalesTotal } from 'graphql/customer/customerOrdersInterfaces';
import { SummaryLine } from 'ui/component/sales-components/summary-line';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import styles from './style.css';

export interface IPrintHeadParams {
    config: ISalesLayoutConfig;
    entityNumber: string;
    entityTypeLabel: string;
    entityDateLabel: string;
    entityDate: string;
    entityTotalLabel?: string;
    salesTotal?: ISalesTotal;
    className?: string;
}

export const PrintHead: React.FC<IPrintHeadParams> = (
    {
        config,
        className,
        entityNumber,
        entityTypeLabel,
        entityDateLabel,
        entityDate,
        entityTotalLabel,
        salesTotal,
    },
) => {
    const [totalAmount, setTotalAmount] = useState<number>(0.00);
    const t = usePhraseTranslater();

    const { salesConfig } = config;
    const { websiteName } = salesConfig;

    useEffect(() => {
        if (!salesTotal) {
            return;
        }

        const { grand_total: grandTotal } = salesTotal;
        const { value: grandTotalValue } = grandTotal;
        setTotalAmount(grandTotalValue);
    }, [salesTotal]);

    return (
        <div
            className={classes(styles.printHeadInfoWrapper, (className || ''))}
            data-id={`print-head-info-entity-${entityTypeLabel}`}
        >
            <div className={classes(styles.printHeadInfoRow, styles.date)}>
                <div className={styles.label}>{entityDateLabel}</div>
                <div className={styles.text}>{entityDate}</div>
            </div>
            <div className={classes(styles.printHeadInfoRow, styles.number)}>
                <div className={styles.label}>{t('%1 %2 number:', websiteName, entityTypeLabel)}</div>
                <div className={styles.text}>{entityNumber}</div>
            </div>
            {salesTotal && (
                <SummaryLine
                    label={entityTotalLabel}
                    amount={totalAmount}
                    lineClassName={classes(styles.printHeadInfoRow, styles.total)}
                    lineRowClassName={styles.totalRow}
                    labelClassName={styles.label}
                    priceClassName={styles.value}
                />
            )}
        </div>
    );
};
